exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-hosting-voorwaarden-tsx": () => import("./../../../src/pages/hosting-voorwaarden.tsx" /* webpackChunkName: "component---src-pages-hosting-voorwaarden-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-voorwaarden-tsx": () => import("./../../../src/pages/voorwaarden.tsx" /* webpackChunkName: "component---src-pages-voorwaarden-tsx" */),
  "component---src-pages-werkwijze-tsx": () => import("./../../../src/pages/werkwijze.tsx" /* webpackChunkName: "component---src-pages-werkwijze-tsx" */)
}

